<template>
  <!-- payment:methods START -->
  <div class="account-payment-methods">
    <!-- options START -->
    <div class="account-payment-methods-options">

      <!-- options:option START -->
      <div class="account-payment-methods-options-option">

        <router-link
          :to="'/'"
          class="f-link"
        ></router-link>

        <div class="icon">
          <img width="128" :src="iconCreditCard" />
        </div>

        <div
          class="headline"
          v-html="$t('account.paymentmethods.options.creditcard')"
        ></div>
      </div>
      <!-- options:option END -->

      <!-- options:option START -->
      <div class="account-payment-methods-options-option">
        <router-link
          :to="'/'"
          class="f-link"
        ></router-link>

        <div class="icon">
          <img width="128" :src="iconSepa" />
        </div>

        <div
          class="headline"
          v-html="$t('account.paymentmethods.options.sepa')"
        ></div>
      </div>
      <!-- options:option END -->

    </div>
    <!-- options END -->
  </div>
  <!-- payment:methods END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import ICONS from '../../utils/icons';

const {
  iCreditCard,
  iSepa,
} = ICONS;

export default {
  name: 'payment-methods',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.paymentmethods.headline'),
      /**
       * Icons
       */
      iconCreditCard: localStorage && localStorage.getItem('theme') === 'light' ? iCreditCard.defaultUrl : iCreditCard.greenUrl,
      iconSepa: localStorage && localStorage.getItem('theme') === 'light' ? iSepa.defaultUrl : iSepa.greenUrl,
    };
  },
  methods: {
    paybackInfo() {
      this.$notify.info({
        message: this.$t('account.paymentmethods.notify.message'),
        position: 'bottom-right',
        duration: 0,
      });
    },
  },
  mounted() {
    this.paybackInfo();
  },
};
</script>
